import React, { useMemo } from 'react';

// Chakra
import { Box, Button, Heading, HStack, List, Text } from '@chakra-ui/react';

import { MdOpenInNew } from 'react-icons/md';

// Interfaces
import { Operation } from '@interfaces/status.interface';
import { FormattedMessage } from 'react-intl';

export const StatusTransactions: React.FC<Operation> = ({ details }) => {
  // Memo
  const transactions = useMemo(() => {
    const explorerLinks = details.explorer?.split('\n') || [];
    const txHashes = details.txHash?.split('\n') || [];

    return txHashes.map((txHash, index) => ({
      explorer: explorerLinks[index],
      txHash
    }));
  }, [details]);

  return transactions && transactions.length > 0 ? (
    <Box mt={9}>
      <Heading as="h3" px={6} size="md">
        <FormattedMessage id="transactions.title" />
      </Heading>

      <List mt={4} spacing={4}>
        {transactions.map(({ explorer, txHash }) => (
          <HStack
            bg="blackAlpha.50"
            borderRadius={12}
            justifyContent="space-between"
            p={3}
          >
            <Text fontSize="xl" fontWeight="medium" px={3}>
              {`${txHash.slice(0, 6)}...${txHash.slice(-6)}`}
            </Text>

            <Button
              as="a"
              colorScheme="teal"
              href={explorer}
              rightIcon={<MdOpenInNew />}
              target="_blank"
            >
              <FormattedMessage id="common.view_on_explorer" />
            </Button>
          </HStack>
        ))}
      </List>
    </Box>
  ) : null;
};
