import { RestLink } from 'apollo-link-rest';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

// Chakra
import { ChakraProvider, ColorModeScript, theme } from '@chakra-ui/react';

// App
import { App } from './App';

// Utils
import reportWebVitals from '@utils/reportWebVitals';
import * as serviceWorker from '@utils/serviceWorker';
import { ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client';

const restLink = new RestLink({
  endpoints: {
    v1: 'https://ps-api.haddan.ru/op'
  }
});

const client = new ApolloClient({
  cache: new InMemoryCache(),
  connectToDevTools: true,
  link: restLink
});

ReactDOM.render(
  <React.StrictMode>
    <ColorModeScript />

    <ApolloProvider client={client}>
      <BrowserRouter>
        <ChakraProvider theme={theme}>
          <App />
        </ChakraProvider>
      </BrowserRouter>
    </ApolloProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
