import { useQuery } from '@apollo/client';
import React, { useMemo } from 'react';
import Countdown from 'react-countdown';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';

// Chakra
import {
  Badge,
  Box,
  Center,
  Container,
  Heading,
  HStack,
  Spinner,
  StatGroup,
  Text
} from '@chakra-ui/react';
import { MdAlarm } from 'react-icons/md';

// Components
import { StatusAmount } from './components/Amount';

// GrpahQL
import GET_STATUS from './Status.gql';

// Interfaces
import { Operation, OperationStatus } from '@interfaces/status.interface';

// Layouts
import { StatusInstructions } from './layouts/Instructions';
import { StatusDescription } from './layouts/Description';
import { StatusTransactions } from './layouts/Transactions';

export const Status: React.FC = () => {
  // Setup
  const { operationId } = useParams<{ operationId: string }>();

  // Data
  const { data: { operation } = {}, loading } = useQuery<{
    operation: Operation;
  }>(GET_STATUS, {
    pollInterval: 15000,
    variables: { operationId }
  });

  // Memo
  const badgeProps = useMemo(() => {
    switch (operation?.status) {
      case OperationStatus.FAILED:
        return {
          children: <FormattedMessage id="status.failed" />,
          colorScheme: 'red'
        };
      case OperationStatus.IN_PROGRESS:
        return {
          children: <FormattedMessage id="status.inprogress" />,
          colorScheme: 'blue'
        };
      case OperationStatus.SUCCESS:
        return {
          children: <FormattedMessage id="status.success" />,
          colorScheme: 'green'
        };
    }
  }, [operation]);

  return (
    <Container maxW="container.sm" p={6}>
      {loading ? (
        <Center>
          <Spinner />
        </Center>
      ) : operation ? (
        <>
          <HStack justifyContent="space-between" p={5}>
            <Heading as="h3" size="md">
              {operation.partnerName}
              <Badge {...badgeProps} ml={2} variant="solid" />
            </Heading>

            {operation.till && (
              <HStack bg="orange.50" borderRadius={8} color="orange" p={2}>
                <MdAlarm />
                <Countdown date={operation.till} daysInHours={true} />
              </HStack>
            )}
          </HStack>

          <StatGroup bg="blackAlpha.100" borderRadius={12} p={4}>
            <StatusAmount
              title={<FormattedMessage id="price.contributed" />}
              value={operation.value}
              unit={operation.currencyName || operation.currency}
            />
            <StatusAmount
              title={<FormattedMessage id="price.total" />}
              value={operation.sumRequired}
              unit={operation.currencyName || operation.currency}
            />
          </StatGroup>

          {operation.status === OperationStatus.FAILED && operation.error && (
            <Box bg="red.100" color="red" borderRadius={12} mt={2} p={4}>
              <Text fontWeight="medium">{operation.error}</Text>
            </Box>
          )}

          <StatusTransactions {...operation} />
          <StatusDescription {...operation} />

          {operation.status === OperationStatus.IN_PROGRESS && (
            <StatusInstructions {...operation} />
          )}
        </>
      ) : null}
    </Container>
  );
};
