import React from 'react';

// Chakra
import { Flex, Stat, StatLabel, StatNumber, Text } from '@chakra-ui/react';

export interface StatucAmountProps {
  title: React.ReactNode;
  value: number;
  unit: string;
}

export const StatusAmount: React.FC<StatucAmountProps> = ({
  title,
  value,
  unit
}) => {
  return (
    <Stat>
      <StatLabel>{title}</StatLabel>
      <StatNumber>
        <Flex align="end">
          {value}
          <Text color="blackAlpha.500" fontSize="xs" fontWeight="bold" m={1}>
            {unit}
          </Text>
        </Flex>
      </StatNumber>
    </Stat>
  );
};
