export enum OperationStatus {
  IN_PROGRESS = 'INPROGRESS',
  FAILED = 'FAILED',
  SUCCESS = 'OK'
}

export interface Operation {
  readonly id: string;
  readonly currency: string;
  readonly currencyName: string;
  readonly description: string;
  readonly details: {
    readonly address: string;
    readonly explorer: string;
    readonly txHash: string;
  };
  readonly error: string;
  readonly instruction: string;
  readonly partnerName: string;
  readonly status: OperationStatus;
  readonly sumRequired: number;
  readonly till: string;
  readonly value: number;
}
