import React, { useCallback, useState } from 'react';
import { IntlProvider } from 'react-intl';
import { Route, Routes } from 'react-router-dom';

// Chakra
import { Container, HStack, Select } from '@chakra-ui/react';
import { MdLanguage } from 'react-icons/md';

// i18n
import EN from './i18n/en.json';
import RU from './i18n/ru.json';

// Pages
import { Status } from '@pages/Status';

enum LOCALE {
  EN = 'en',
  RU = 'ru'
}

const MESSAGES = {
  [LOCALE.EN]: EN,
  [LOCALE.RU]: RU
};

export const App: React.FC = () => {
  // State
  const [locale, setLocale] = useState<LOCALE>(
    (navigator.language || (navigator as any).userLanguage || LOCALE.EN).split(
      '-'
    )[0]
  );

  // Handlers
  const handleLocaleChange = useCallback(
    (event: React.SyntheticEvent<HTMLSelectElement>) =>
      setLocale(event.currentTarget.value as LOCALE.EN),
    []
  );

  return (
    <Container maxW="container.sm">
      <HStack justifyContent="end" spacing={4} p={6}>
        <MdLanguage />

        <Select
          name="locale"
          onChange={handleLocaleChange}
          value={locale}
          width="80px"
        >
          <option value={LOCALE.EN}>EN</option>
          <option value={LOCALE.RU}>RU</option>
        </Select>
      </HStack>

      <IntlProvider
        defaultLocale="en"
        locale={locale}
        messages={MESSAGES[locale]}
      >
        <Routes>
          <Route element={<Status />} path="/op/:operationId" />
        </Routes>
      </IntlProvider>
    </Container>
  );
};
