import React from 'react';
import { FormattedMessage } from 'react-intl';
import QRCode from 'react-qr-code';

// Chakra
import {
  Box,
  Button,
  Heading,
  HStack,
  Text,
  Tooltip,
  useClipboard,
  VStack
} from '@chakra-ui/react';

import { MdContentCopy } from 'react-icons/md';

// Interfaces
import { Operation } from '@interfaces/status.interface';

export const StatusInstructions: React.FC<Operation> = ({
  details: { address },
  instruction
}) => {
  // Setup
  const { hasCopied, onCopy } = useClipboard(address);

  return (
    <Box mt={8}>
      <Heading as="h3" px={6} size="md">
        <FormattedMessage id="instruction.title" />
      </Heading>

      <HStack align="start" mt={4} spacing={8}>
        <Box flex="1 1 auto">
          <Text
            dangerouslySetInnerHTML={{ __html: instruction }}
            textAlign="justify"
          />
        </Box>

        <VStack align="center" flex="0 0 160px" spacing={4}>
          <Box borderRadius={8} overflow="hidden">
            <QRCode size={140} value="test" />
          </Box>

          <VStack>
            <Tooltip label={<FormattedMessage id="common.copy" />}>
              <Button
                colorScheme="blue"
                onClick={onCopy}
                rightIcon={<MdContentCopy />}
              >
                {hasCopied ? (
                  <FormattedMessage id="common.copied" />
                ) : (
                  `${address.slice(0, 4)}...${address.slice(-4)}`
                )}
              </Button>
            </Tooltip>
          </VStack>
        </VStack>
      </HStack>
    </Box>
  );
};
