import React from 'react';
import { FormattedMessage } from 'react-intl';

// Chakra
import { Box, Heading, Text } from '@chakra-ui/react';

// Interfaces
import { Operation } from '@interfaces/status.interface';

export const StatusDescription: React.FC<Operation> = ({ description }) => (
  <Box mt={8}>
    <Heading as="h3" px={6} size="md">
      <FormattedMessage id="description.title" />
    </Heading>

    <Box mt={4}>
      <Text>{description}</Text>
    </Box>
  </Box>
);
